<template>
  <NavQuestion
    :heading="heading"
    :number="'5'"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <InputSwitch
        :checked="funeral.funeral_wishes_required"
        @toggle="save"
      ></InputSwitch>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="'/funeral/plan'"
        v-bind:center="false"
        v-bind:loading="loading"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputSwitch from '@/common/ui/InputSwitch'

export default {
  name: 'FuneralWishesOption',
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputSwitch
  },
  computed: {
    isValidated() {
      return true
    },
    heading() {
      return 'Do you have any other funeral related wishes?'
    },
    subHeading() {
      return (
        'It is not uncommon to include additional funeral related wishes in your Will, these wishes can help ' +
        'guide your executors when they are arranging your funeral.'
      )
    },
    forwardTo() {
      if (this.funeral.funeral_wishes_required) return '/funeral/wishes'
      return '/funeral/check_and_confirm'
    },
    funeral() {
      return this.$store.getters.funeral
    }
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    save() {
      if (this.funeral) {
        this.$store.commit('funeral', {
          funeral_wishes_required: !this.funeral.funeral_wishes_required
        })
        if (!this.funeral.funeral_wishes_required) {
          this.resetFuneralWishes()
        }
      }
    },
    resetFuneralWishes() {
      this.$store.commit('funeral', {
        funeral_wishes: ''
      })
    }
  }
}
</script>

<style></style>
